import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { SharpenInfo } from 'src/app/shared/interfaces/sharpen-info.interface';
import { LoadSharpenInfo } from '../store/actions/sharpen.action';
import { AppState } from '../store/state/app.state';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class SharpenService {
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  public getSharpenInfo(sharpenInfoUrl: string): Observable<SharpenInfo> {
    return this.http.get<SharpenInfo>(`${sharpenInfoUrl}`).pipe(
      tap((sharpenInfoResponse: SharpenInfo) => {
        const sharpenInfo = {
          sharpenInfo: sharpenInfoResponse,
        };
        this.store.dispatch(new LoadSharpenInfo(sharpenInfo));
        return sharpenInfoResponse;
      }),
      catchError(this.handleError),
    );
  }

  public getMHToken(getmhTokenUrl: string): Observable<HttpResponse<null>> {
    return this.http
      .post<null>(getmhTokenUrl, {}, { observe: 'response' })
      .pipe(
        tap((response: HttpResponse<null>) => {}),
        catchError(this.handleError),
      );
  }

  handleError(error: HttpErrorResponse) {
    return throwError(() => {
      return error;
    });
  }
}
