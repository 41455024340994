import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuidLibModule } from '@mhe/quid';
import { TranslateModule } from '@ngx-translate/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { UPModule } from 'up';
import { SharpenButtonComponent } from './components/sharpen-button/sharpen-button.component';
@NgModule({
  declarations: [SharpenButtonComponent],
  imports: [
    CommonModule,
    QuidLibModule,
    TranslateModule,
    NgbToastModule,
    NgbModule,
    FormsModule,
    UPModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [UPModule, SharpenButtonComponent],
})
export class SharedModule {}
