import { Action } from '@ngrx/store';
import { SharpenInfoState } from '../state/sharpen.state';

export enum SharpenActions {
  LoadSharpenInfo = '[SharpenActions] Load Sharpen Info',
}

export class LoadSharpenInfo implements Action {
  public readonly type = SharpenActions.LoadSharpenInfo;
  constructor(public payload: SharpenInfoState) {}
}

export type SharpenActionsUnion = LoadSharpenInfo;
