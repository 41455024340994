<button
  type="button"
  class="sharpen-banner-button"
  [class.header-style]="isHeaderStyle()"
  data-automation-id="sharpen-redirect-btn"
  (click)="onClickSharpenButton()">
  <img
    alt="Sharpen"
    class="sharpen-logo"
    src="../../../../assets/Sharpen_logo.svg" />
  <span class="sharpen-subtext">
    {{ "LEARNER.SHARPEN_BUTTON.SUBTEXT" | translate }}
  </span>
  <img
    class="sharpen-redirect-logo"
    [alt]="'LEARNER.SHARPEN_BUTTON.REDIRECT_LOGO' | translate"
    src="../../../../assets/Redirect_logo.svg" />
</button>
