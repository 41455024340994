import { Component, input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SharpenService } from '../../services/sharpen.service';
import { finalize, take } from 'rxjs';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'go-sharpen-button',
  templateUrl: './sharpen-button.component.html',
  styleUrl: './sharpen-button.component.scss',
})
export class SharpenButtonComponent {
  isHeaderStyle = input(false);
  sharpenWindow: Window | null = null;

  constructor(
    private sharedService: SharedService,
    private sharpenService: SharpenService,
  ) {}

  onClickSharpenButton(): void {
    this.sharpenService
      .getMHToken(`${environment.apiUrl}/up/jwt/token/refresh`)
      .pipe(
        take(1),
        finalize(() => this.navigateToSharpen()),
      )
      .subscribe();
  }

  navigateToSharpen() {
    const isbn = this.sharedService.getISBN();
    const sharpenUrl = `${environment.sharpenUrl}/sso/connect?isbn=${isbn}`;

    if (this.sharpenWindow && !this.sharpenWindow.closed) {
      this.sharpenWindow.focus();
    } else {
      this.sharpenWindow = window.open(sharpenUrl, '_blank');
    }
  }
}
